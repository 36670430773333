<template>
  <div v-if="show" class="text-white px-6 py-3 border-0 rounded relative my-4 bg-green-400">
    <span class="inline-block align-middle mr-8">
      <b class="capitalize">{{title || ''}}</b> {{message || ''}}
    </span>
    <button
      @click="close"
      class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
    >
      <span>×</span>
    </button>
  </div>
</template>

<script>
  export default {
    props: ['show', 'message', 'title'],
    methods: {
      close(){
        this.$emit('close');
      }
    }
  }
</script>