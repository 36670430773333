<template>
  <header
    class="w-full flex justify-between mt-16 font-sans uppercase"
    style="font-size: 12px"
    @mouseleave="selected = null"
  >
    <a href="https://artaround.org">
      <img
        src="../../assets/artaround-logo.png"
        style="min-height: 75px; width: 180px; object-fit: contain;  no-repeat !important"
      />
    </a>
    <nav class="flex-row w-full lg:w-auto justify-end pt-3">
      <span class="relative group inline md:mr-4 mr-8">
        <a href="https://map.artaround.org" @mouseover="selected = 2">
          <span class="mr-1 uppercase hover:text-red-500">Map</span>
        </a>
        <div
          class="absolute sm:-ml-2 md:ml-10 lg:-ml-10 -mt-1 pr-12 overflow-hidden transition-all max-h-0 duration-500 z-50"
          ref="mapOptions"
          :style="
            selected == 2
              ? 'max-height: ' + $refs.mapOptions.scrollHeight + 'px'
              : ''
          "
          style="width: 220px"
        >
          <ul class="bg-green-900 text-white text-left">
            <li>
              <a
                class="rounded-t bg-green-900 hover:text-red-500 py-2 px-4 block whitespace-no-wrap"
                href="https://map.artaround.org"
                >Feed</a
              >
            </li>
            <li>
              <a
                class="rounded-t bg-green-900 hover:text-red-500 py-2 px-4 block whitespace-no-wrap"
                href="https://artaround.org/guides"
                >Guides</a
              >
            </li>
            <li>
              <a
                class="rounded-t bg-green-900 hover:text-red-500 py-2 px-4 block whitespace-no-wrap"
                href="https://artaround.org/collections"
                >Collections</a
              >
            </li>
            <li>
              <a
                class="rounded-t bg-green-900 hover:text-red-500 py-2 px-4 block whitespace-no-wrap"
                href="https://artaround.org/publications"
                >Publications</a
              >
            </li>
          </ul>
        </div>
      </span>

      <span class="relative group inline md:mr-4 mr-8">
        <a href="https://artaround.org/community" @mouseover="selected = 3">
          <span class="mr-1 uppercase hover:text-red-500">Community</span>
        </a>
        <div
          class="absolute md:ml-20 lg:ml-6 -mt-1 overflow-hidden transition-all max-h-0 duration-500 z-50"
          ref="communityOptionsRef"
          :style="
            selected == 3
              ? 'max-height: ' + $refs.communityOptionsRef.scrollHeight + 'px'
              : ''
          "
          style="width: 180px"
        >
          <ul class="bg-green-900 text-white text-left">
            <li>
              <a
                class="rounded-t bg-green-900 hover:text-red-500 py-2 px-4 block whitespace-no-wrap"
                href="https://artaround.org/ambassadors"
                >Ambassadors</a
              >
            </li>
            <li>
              <a
                class="rounded-t bg-green-900 hover:text-red-500 py-2 px-4 block whitespace-no-wrap"
                href="https://artaround.org/partners"
                >Partners</a
              >
            </li>
            <li>
              <a
                class="rounded-t bg-green-900 hover:text-red-500 py-2 px-4 block whitespace-no-wrap"
                href="https://artaround.org/supporters"
                >Supporters</a
              >
            </li>
          </ul>
        </div>
      </span>

      <div class="inline" @mouseenter="selected = null">
        <a class="px-4 py-2 mt-2 md:mr-0 mr-4 hover:text-red-500"
           href="https://artaround.org/news"
          >News</a
        >
        <a class="px-4 py-2 mt-2 md:mr-0 mr-4 hover:text-red-500"
           href="https://arrow.artaround.org"
           target="_blank"
          >Magazine</a
        >
        <a class="px-4 py-2 mt-2 hover:text-red-500"
           href="https://artaround.org/about">About</a>
        <button
          @click="uploadArt()"
          class="py-4 px-4 rounded-xl bg-red-600 focus:outline-none text-white hover:text-blue-800 font-bold uppercase"
        >
          Upload Art
        </button>
      </div>
    </nav>
  </header>
</template>
<script>
export default {
  data: () => {
    return {
      selected: null,
    };
  },
  methods: {
    uploadArt() {
      this.$emit("uploadArt");
    },
  },
};
</script>
 <style>
#menu-toggle:checked + #menu {
  display: block;
}
</style>