<template>
  <div class="relative my-2 w-1/2 md:w-auto md:mx-1">
    <a @click="isOpen = !isOpen" class="visit px-2">
      <i class="fa fa-share"></i> Share
    </a>
    <button v-if="isOpen" @click="isOpen = false" tabindex="-1" class="fixed inset-0 h-full w-full z-10 bg-white opacity-0 cursor-default"></button>
    <div v-if="isOpen" class="absolute lg:right-0 mt-2 py-2 w-64 sm:w-48 bg-white rounded-lg shadow-xl z-20">
      <a :href="facebookBase" class="block px-4 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white">Facebook</a>
      <a :href="twitterBase" class="block px-4 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white">Twitter</a>
      <a :href="redditBase" class="block px-4 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white">Reddit</a>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      sharedURL: String,
    },
    data() {
      return {
        isOpen: false
      }
    },
    computed: {
      facebookBase: function () {
        const base = 'https://www.facebook.com/sharer.php?u=';
        return  base + this.sharedURL;
      },
      twitterBase: function () {
        const base = 'https://twitter.com/intent/tweet?&url=';
        return base + this.sharedURL;
      },
      redditBase: function () {
        const base = 'http://www.reddit.com/submit?url=';
        return base + this.sharedURL;
      }
    },
    created() {
      const handleEscape = (e) => {
        if (e.key === 'Esc' || e.key === 'Escape') {
          this.isOpen = false
        }
      }
      document.addEventListener('keydown', handleEscape)

      this.$once('hook:beforeDestroy', () => {
        document.removeEventListener('keydown', handleEscape)
      })
    }
  }
</script>
<style>
  .visit {
    text-decoration: none;
    cursor: pointer;
    color: #333;
    font-size: 1.2rem;
  }
</style>