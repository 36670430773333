
export const getMainPhoto = (art) => {
    if (!art.photos || !art.photos.length) {
        return null;
    }
    return art.photos.find((photo) => !!photo.primary)
}

export const sortPhotos = (art) => {
    if (!art.photos || !art.photos.length) {
        return [];
    }
    return [...art.photos].sort(({primary}) => {
        return primary ? -1 : 1;
    });
}