import axios from "axios";

const getAllCities = async () => {
    const {data} = await axios.get("/api/arts/locations.json");
    return data
        .filter((art) => !!art.city)
        .map((art) => art.city.trim());
}
const getAllArtists = async () => {
    const {data} = await axios.get("/api/artists.json");
    return data
        .map((artist) => artist.name);
}
const getAllTags = async () => {
    const {data} = await axios.get("/api/tags.json");
    return data
        .map((tag) => tag.name);
}
const getAllCategories = async () => {
    const {data} = await axios.get("/api/categories.json");
    return data
        .map((category) => category.name);
}

const getArtsByCity = async (city, {page: page}) => {
    const {data} = await axios.get(`/api/arts.json?page=${page}&per=10${city ? "&city=" + city : ''}`)
    return data ? data.arts : [];
}

const getArtsByTag = async (tag, {page: page}) => {
    const {data} = await axios.get(`/api/arts.json?page=${page}&per=10${tag ? "&tag=" + tag : ''}`)
    return data ? data.arts : [];
}

const getArtsByCategory = async (category, {page: page}) => {
    const {data} = await axios.get(`/api/arts.json?page=${page}&per=10${category ? "&category=" + category : ''}`)
    return data ? data.arts : [];
}

const getArtsByArtist = async (artist, {page: page}) => {
    const {data} = await axios.get(`/api/arts.json?page=${page}&per=10${artist ? "&artist=" + artist : ''}`)
    return data ? data.arts : [];
}

const getArtsByYear = async (year, {page: page}) => {
    const {data} = await axios.get(`/api/arts.json?page=${page}&per=10${year ? "&year=" + year : ''}`)
    return data;
}

const getArts = async ({sort: sort, lat: lat, lng: lng, page: page, tag: tag, artist: artist, category: category, city: city}) => {
    const urlBase = `/api/arts.json?page=${page}&per=10&sort=${sort}`;
    const urlWithLatLng = lat && lng ? `${urlBase}&lat=${lat}&lng=${lng}`: urlBase;
    const urlWithTag = tag ? `&tag=${tag}` : '';
    const urlWithArtist = artist ? `&artist=${artist}` : '';
    const urlWithCategory = category ? `&category=${category}` : '';
    const urlWithCity = city ? `&city=${city}` : '';
    const url = `${urlWithLatLng}${urlWithTag}${urlWithArtist}${urlWithCategory}${urlWithCity}`
    const {data} = await axios.get(url)
    return data;
}

const createFlagReport = async (artId, {lat: lat, lng: lng, email: email, name: name, comment: comment}) => {
    const urlBase = `/api/flags`;
    const {data} = await axios.post(urlBase, {
        art_id: artId,
        email: email,
        lat: lat,
        lng: lng,
        name: name,
        comment: comment,
    });
    return data;
}

const getArt = async (id, preview = false) => {
    const urlBase = preview ? `/api/arts/preview/${id}.json` : `/api/arts/${id}.json`;
    const {data} = await axios.get(urlBase);
    return data;
}

const getAllCommentsOfArt = async (id) => {
    const urlBase = `/api/arts/${id}/comments.json`;
    const {data} = await axios.get(urlBase);
    return data;
}

export default {
    getAllCities,
    getArtsByArtist,
    getArtsByCity,
    getArtsByTag,
    getArtsByCategory,
    getArtsByYear,
    getAllArtists,
    getAllTags,
    getAllCategories,
    getArts,
    createFlagReport,
    getArt,
    getAllCommentsOfArt,
}