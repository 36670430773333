<template>
  <div id="app" v-cloak>
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    const haveProduction = isProduction || false;
    if (haveProduction) {
      if (window.location.protocol === 'http:'){
        window.location.href = 'https://' + window.location.host
      }
    }
  },
  components: {},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

body {
  font-family: "Roboto", sans-serif;
}

[v-cloak] {
  display: none;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 40px auto;
  font-size: 5px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #fe0205;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.error {
  text-align: center;
  margin-top: 100px;
  font-size: 2rem;
  font-weight: bold;
  color: #fe0205;
}

.style-chooser .vs__dropdown-toggle {
  border: none;
  text-transform: lowercase;
  font-variant: small-caps;
  min-height: 60px;
  font-size: 1.2rem;
  width: 105%;
  margin-bottom: 10px;
  outline: none;
  border-radius: 5px;
}

.style-chooser .vs__search::placeholder {
  padding-left: 10px;
  color: #7d7d7d;
}

.style-chooser .vs__dropdown-toggle {
  border: 1px solid #eee;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.style-chooser .vs__dropdown-menu {
  display: none;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}
</style>
