<template>
  <div>
    <div v-if="showModal"
         class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="flex w-full">
        <div class="px-2 mx-auto mt-28 sm:mt-auto md:px-48 w-full bg-white max-w-screen-lg">
          <form-wizard
            color="#ff0000"
            stepSize="sm"
            title="Upload an artwork"
            subtitle=""
          >
            <template v-slot:title>
              <div class="text-right">
                <button @click="close()" class="text-xl">X</button>
              </div>
            </template>
            <tab-content title="Photo">
              <div class="p-5 rounded-md shadow-sm w-full flex flex-col text-left">
                <div class="flex w-full flex-row">
                  <input type="file" id="file" @change="handleFileUpload" :ref="file"/>
                </div>
                <div class="mt-6 w-full">
                  <label
                    for="photo-credit"
                    class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                  >Photo credit</label
                  >
                  <input
                    type="text"
                    name="name"
                    id="photo-credit"
                    placeholder="Photo credit"
                    v-model="photo"
                    required
                    class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none"
                  />
                </div>
                <div class="mt-6 w-full">
                  <label
                    for="photographer-url"
                    class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                  >Photographer URL</label
                  >
                  <input
                    type="text"
                    name="name"
                    id="photographer-url"
                    placeholder="Photographer URL"
                    v-model="url"
                    required
                    class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none"
                  />
                </div>
                <div class="flex flex-wrap justify-center mt-6">
                  <div class="w-64 px-4">
                    <img :src="image"
                         alt="image"
                         v-if="image && !imageDefault"
                         class="shadow-lg rounded max-w-full h-auto align-middle border-none object-contain"
                    />

                    <img :src="imageDefault"
                         alt="image"
                         v-if="image && imageDefault"
                         class="shadow-lg rounded max-w-full h-auto align-middle border-none object-cover"
                    />

                  </div>
                </div>
              </div>
            </tab-content>
            <tab-content title="Tag">
              <div class="p-5 rounded-md shadow-sm w-full flex flex-col text-left">
                <div class="w-full">
                  <label
                    for="name"
                    class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                  >Categories</label>
                  <v-select
                    class="text-lg text-gray-500 py-2"
                    aria-placeholder="Categories"
                    placeholder="Select or press enter"
                    multiple
                    :select-on-key-codes="[188, 13]"
                    select-on-tab
                    v-model="art.categories"
                    label="name"
                    :options="artCategories"
                    :reduce="category => category.name"
                  />
                  <div class="mb-2 text-sm text-left text-red-500">
                    {{art.categories.length ? '' : 'Please select at least one category'}}
                  </div>
                </div>
                <div class="w-full mt-2">
                  <label
                    for="name"
                    class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                  >Descriptive tags</label
                  >
                  <v-select
                    class="text-lg text-gray-500 py-2"
                    aria-placeholder="Descriptive Tags"
                    placeholder="Select or press enter"
                    :select-on-key-codes="[188, 13]"
                    select-on-tab
                    multiple
                    v-model="art.tags"
                    label="name"
                    :options="artDescriptiveTags"
                    :reduce="tag => tag.name"
                  />
                  <div class="mb-2 text-sm text-left text-red-500">
                    {{art.tags.length ? '' : 'Please select at least one tag'}}
                  </div>
                </div>
                <div class="w-full mt-2">
                  <label
                    for="name"
                    class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                  >Title of Artwork</label
                  >
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Title of Artwork"
                    v-model="art.title"
                    required
                    class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none"
                  />
                  <div class="mb-2 text-sm text-left text-red-500">
                    {{art.title.length ? '' : 'The Art title is required'}}
                  </div>
                </div>
                <div class="w-full mt-2">
                  <label
                    for="name"
                    class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                  >Artist(s)</label
                  >
                  <v-select
                    class="text-lg text-gray-500 py-2"
                    aria-placeholder="Artist(s)"
                    placeholder="Select or press enter to add new"
                    :select-on-key-codes="[188, 13]"
                    select-on-tab
                    taggable
                    multiple
                    v-model="art.artists"
                    label="name"
                    :options="artistsList"
                    @option:created="createArtist"
                    :reduce="artist => artist.id"
                  />
                  <div class="mb-2 text-sm text-left text-red-500">
                    {{art.artists.length ? '' : 'Please select at least one artist'}}
                  </div>
                </div>
              </div>
            </tab-content>
            <tab-content title="Describe">
              <div class="mt-2 w-full">
                <label
                  for="city"
                  class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                >City</label>
                <input
                  type="text"
                  name="name"
                  id="city"
                  placeholder="City"
                  v-model="art.city"
                  class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none"
                />
              </div>
              <div class="mt-2 w-full">
                <label
                  for="year"
                  class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                >Year</label>
                <input
                  type="text"
                  name="name"
                  id="year"
                  placeholder="Year"
                  v-model="art.year"
                  class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none"
                />
              </div>
              <div class="mt-2 w-full">
                <label
                  for="website"
                  class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                >Website</label>
                <input
                  type="text"
                  name="name"
                  id="website"
                  placeholder="Website"
                  v-model="art.website"
                  class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none"
                />
              </div>
              <div class="mt-2 w-full">
                <label
                  for="description"
                  class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                >Description</label>
                <textarea
                  id="description"
                  placeholder="Briefly describe the artwork"
                  v-model="art.description"
                  class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none"
                />
              </div>
            </tab-content>
            <tab-content title="Location">
              <div class="mt-2 w-full">
                <div style="height: 300px; width: 100%;">
                  <GmapMap
                    :center="item.center"
                    :zoom="14"
                    map-type-id="terrain"
                    style="width: 100%; height: 100%"
                  >
                    <GmapMarker
                      :position="item.position"
                      :clickable="false"
                      :draggable="false"
                      :icon="{
                        url: require('../assets/artaround-pin.png'),
                        scaledSize: {width: 30, height: 45, f: 'px', b: 'px',},
                }"
                    />
                  </GmapMap>
                </div>
                <div class="mt-2 w-full">
                  <label
                    for="description"
                    class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                  >Address</label>
                  <places-autocomplete
                    id="art-location"
                    placeholder="Enter the address"
                    classes="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none"
                    v-model="art.address"
                    :fields="[
                      'geometry',
                      'name',
                      'formatted_address',
                      'address_components',
                      'types',
                      'vicinity'
                      ]"
                    @placeChange="handleArtLocation"
                  />
                </div>
                <div class="mt-2 w-full">
                  <label
                    for="description"
                    class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                  >Address description</label>
                  <textarea placeholder="Share some details about the location"
                            class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none"
                            v-model="art.location_description"/>
                </div>
              </div>
            </tab-content>
            <template slot="footer" slot-scope="props">
              <div class="wizard-footer-left">
                <wizard-button
                  v-if="step == 1"
                  @click.native="close()"
                  :style="props.fillButtonStyle"
                >Cancel
                </wizard-button>
                <wizard-button
                  v-if="step > 1 && !props.isLastStep"
                  @click.native="props.prevTab(); step--"
                  :style="props.fillButtonStyle"
                >Previous
                </wizard-button>
              </div>
              <div class="wizard-footer-right">
                <wizard-button
                  v-if="!props.isLastStep"
                  :disabled="isDisabled"
                  @click.native="props.nextTab(); step++"
                  class="wizard-footer-right"
                  :style="props.fillButtonStyle"
                >Next
                </wizard-button>

                <wizard-button
                  v-else
                  :disabled="isDisabled  || loading"
                  @click.native="handleSubmit"
                  class="wizard-footer-right finish-button"
                  :style="props.fillButtonStyle"
                >
                  {{ props.isLastStep ? "Submit" : "Next" }}
                </wizard-button>
              </div>
            </template>
          </form-wizard>
        </div>
      </div>
    </div>
    <div v-if="showModal" @click="close()" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
  import axios from "axios";
  import EXIF from 'exif-js'
  import {FormWizard, TabContent, WizardButton} from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import PlaceHolderImage from "../assets/placeholder.png";
  import PlacesAutocomplete from "./PlacesAutocomplete.vue";
  import {getLatLngImage, latLngFromPlace} from "../utils";
  import {getAddressFromLatLng, getCurrentPosition} from "../features/geolocation";

  export default {
    components: {
      FormWizard,
      TabContent,
      WizardButton,
      PlacesAutocomplete,
    },
    props: ["showModal"],
    name: "ArtworkWizardUploader",
    mounted: function () {
      this.$nextTick(function () {
        this.updateTitleStyles();
        this.fetchCategories();
        this.fetchDescriptiveTags();
        this.fetchArtistsList();
        this.centerMap();
      });
    },
    data() {
      return {
        file: {},
        image: "",
        imageDefault: "",
        fileName: "",
        photo: "",
        url: "",
        step: 1,
        art: {
          categories: [],
          tags: [],
          title: '',
          artists: [],
          year: '',
          city: '',
          website: '',
          description: '',
          location_description: '',
          address: '',
          lat: 0,
          lng: 0,
        },
        defaultArt: {
          categories: [],
          tags: [],
          title: '',
          artists: [],
          year: '',
          city: '',
          website: '',
          description: '',
          location_description: '',
          address: '',
          lat: 0,
          lng: 0,
        },
        artCategories: [],
        artDescriptiveTags: [],
        artistsList: [],
        item: {
          haveLatLng: false,
          center: {
            lat: 41.964670069207244,
            lng: -87.68149129003832
          },
          position: {
            lat: 41.964670069207244,
            lng: -87.68149129003832
          }
        },
        loading: false,
      }
    },
    methods: {
      handleArtLocation(place) {
        const {lat, lng} = latLngFromPlace(place);
        this.item.position.lat = lat;
        this.item.position.lng = lng;
        this.item.center.lat = lat;
        this.item.center.lng = lng;
        this.art.lat = lat;
        this.art.lng = lng;
        this.art.address = place.formatted_address;
      },
      updateTitleStyles() {
        const stepperTitles = document.getElementsByClassName("stepTitle");
        Array.from(stepperTitles).forEach((title) => {
          title.style.marginTop = "1rem";
        });
      },
      close() {
        this.$emit('close');
      },
      reset() {
        this.step = 1;
        this.photo = "";
        this.url = "";
        this.file = {};
        this.image = "";
        this.fileName = "";

        this.art = {...{},...this.defaultArt};

        this.item = {
          center: {
            lat: 41.964670069207244,
            lng: -87.68149129003832
          },
          position: {
            lat: 41.964670069207244,
            lng: -87.68149129003832
          }
        };
        this.loading = false;
      },
      handleFileUpload({ target: { files } }) {
        const [file] = files;

        this.fileName = file.name;
        this.file = file;

        const reader = new FileReader();
        const vm = this.$data

        EXIF.getData(file, function (){
          const latLng = getLatLngImage(this)

          if (!latLng.haveError){
            vm.item.haveLatLng = true;
            vm.item.center = {
              lat: latLng.lat,
              lng: latLng.lng
            }

            vm.item.position = {
              lat: latLng.lat,
              lng: latLng.lng
            }

            vm.art.lat = latLng.lat;
            vm.art.lng = latLng.lng;
          } else {
            getCurrentPosition().then(async (position) => {
              vm.item.center = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              }

              vm.item.position = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              }

              vm.art.lat = position.coords.latitude;
              vm.art.lng = position.coords.longitude;

              try {
                const response = await getAddressFromLatLng({lat: position.coords.latitude, lng: position.coords.longitude})
                if (response.results && response.results.length && response.results[0].formatted_address){
                  vm.art.address = response.results[0].formatted_address;
                }
              } catch (_e) {
                vm.art.address = "";
              }

            }).catch((e) => { console.error(e)})
          }
        });

        reader.onload = e => {
          if (e.target.result){
            if (e.target.result.includes('image/heic')){
              this.imageDefault = PlaceHolderImage;
            } else {
              this.imageDefault = ""
            }
          }
          this.image = e.target.result;
        };

        reader.readAsDataURL(file);
      },
      async fetchCategories() {
        this.artCategories = await axios
          .get("/api/categories.json")
          .then(res => res.data);
      },
      async fetchDescriptiveTags() {
        this.artDescriptiveTags = await axios
          .get("/api/tags.json")
          .then(res => res.data);
      },
      async createArtist(artist) {
        const createdArtist = await axios.post('/api/artists.json', artist).then(res => res.data)
        this.art.artists = this.art.artists.filter(artist => !!artist)
        this.artistsList.push(createdArtist);
        this.art.artists.push(createdArtist.id);
      },
      async fetchArtistsList() {
        this.artistsList = await axios.get("/api/artists.json").then(res => res.data);
      },
      centerMap(){
        navigator.geolocation.getCurrentPosition(pos => {
          const {coords: {latitude, longitude}} = pos;
          this.item.center.lat = latitude;
          this.item.center.lng = longitude;
          this.item.position.lat = latitude;
          this.item.position.lng = longitude;
        });
      },
      async handleSubmit() {
        this.loading = true;

        const {artists, categories, tags, ...rest} = this.art;

        const createdArt = await axios.post('/api/arts.json', {
          ...rest,
          tag_list: tags.join(','),
          category_list: categories.join(','),
          art_artist_ids: artists
        }).then(res=>res.data);

        const formData = new FormData();
        formData.append("art_id", createdArt.id);
        formData.append("image", this.file, this.fileName);
        formData.append("attribution_text", this.photo || 'Anonymous');
        formData.append("attribution_url", this.url);
        formData.append("primary", "true");

        await axios.post('/api/photos.json', formData).then(res=>res.data)
        this.$emit("close");
        this.$router.push('/art/' + createdArt.friendly_id, ()=>{
          this.reset();
          this.$router.go(0);
        })
      },
    },
    computed: {
      isDisabled() {
        if (this.step === 1) {
          if (!this.file.name) {
            return true;
          }
        }

        // TODO: fix validations

        if (this.step === 2) {
          if (
            !this.art.categories.length ||
            !this.art.tags.length ||
            !this.art.title ||
            !this.art.artists.length
          ) {
            return true;
          }
        }

        if (this.step === 4) {
          if(this.item.haveLatLng) {
            return false;
          }
          if (!this.art.address) {
            return true;
          }
        }

        return false;
      }
    },
    watch: {
      step(val) {
        if (val === 4 && this.item.haveLatLng) {
          try {
            getAddressFromLatLng(this.item.position).then((response) => {
              if (response.results && response.results.length && response.results[0].formatted_address){
                this.art.address = response.results[0].formatted_address;
              }
            })
          } catch (e) {
            this.art.address = ""
          }
        }
      },
    }
  };
</script>
