export const latLngFromPlace = (place) => {
  const {location} = place.geometry;
  const lat = location.lat();
  const lng = location.lng();
  return {
    lat: lat || 0,
    lng: lng || 0,
  }
}

export function getLatLngImage(imageData){
  try {
    console.log(imageData)
    const exifLong = imageData.exifdata.GPSLongitude;
    const exifLongRef = imageData.exifdata.GPSLongitudeRef
    const exifLat = imageData.exifdata.GPSLatitude;
    const exifLatRef = imageData.exifdata.GPSLatitudeRef;
    let latitude = 0;
    if (exifLatRef == "S") {
      latitude = (exifLat[0]*-1) + (( (exifLat[1]*-60) + (exifLat[2]*-1) ) / 3600);
    } else {
      latitude = exifLat[0] + (( (exifLat[1]*60) + exifLat[2] ) / 3600);
    }
    let longitude = 0;
    if (exifLongRef == "W") {
      longitude = (exifLong[0]*-1) + (( (exifLong[1]*-60) + (exifLong[2]*-1) ) / 3600);
    } else {
      longitude = exifLong[0] + (( (exifLong[1]*60) + exifLong[2] ) / 3600);
    }


    return {
      haveError: false,
      lat: latitude,
      lng: longitude
    }
  } catch (e) {
    return {
      haveError: true,
      lat: 0,
      lng: 0,
    }
  }
}