import ArtPage from "./views/ArtPage.vue";
import HomePage from "./views/HomePage.vue";
import LayoutBase from "./layouts/LayoutBase.vue";
import VueRouter from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: LayoutBase,
    children: [
      {
        path: "/",
        component: HomePage,
      },
      { path: "/art/:id", component: ArtPage },
      { path: "/art/:id/preview", component: ArtPage },
    ],
  },
];

export const router = new VueRouter({
  mode: "history",
  routes,
});
