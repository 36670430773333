<template>
    <div>
      <div v-if="showUploadDialog" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
        <div class="relative w-auto my-6 mx-2 max-w-sm">
          <!--content-->
          <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <!--header-->
            <div class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
              <h3 class="text-3xl font-semibold">
                Add a photo
              </h3>
              <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" v-on:click="close()">
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
              </button>
            </div>
            <!--body-->
            <div class="relative p-6 flex-auto">
              <input type="file" class="px-2 py-2 w-full" @change="handleFileUpload" :ref="file" />
              <input
                placeholder="Attribution text"
                class="px-2 py-2 bg-gray-200 w-full mt-2 focus:outline-none"
                v-model="attributionText"
              />
              <input
                placeholder="Attribution URL"
                class="px-2 py-2 bg-gray-200 w-full mt-2 focus:outline-none"
                v-model="attributionURL"
              />
              <div class="flex justify-center">
                <img v-if="image" :src="image" alt="image" class="h-32 w-40 object-cover mt-2" />
              </div>
              <div v-if="loading">
                <div class="loader">Loading...</div>
              </div>
            </div>
            <!--footer-->
            <div class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
              <button
                class="text-red-600 bg-transparent border border-solid border-red-600 hover:bg-red-600 hover:text-white active:bg-red-600 font-bold uppercase px-6 py-3 rounded-xl outline-none focus:outline-none mr-1 mb-1"
                type="button"
                style="transition: all .15s ease"
                v-if="!loading && !showNotification"
                @click="close">
                Close
              </button>
              <button
                class="py-3 mb-1 px-6 focus:outline-none rounded-xl bg-red-600 text-white hover:text-blue-800 font-bold uppercase"
                type="button"
                style="transition: all .15s ease"
                v-if="!loading && !showNotification"
                @click="disableSubmitButton ? '':handleSubmit()"
              >
                Save Changes
              </button>
              <notification
                :show="showNotification"
                message="thank you for adding a photo."
                @close="close"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="showUploadDialog" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
</template>
<script>
import axios from "axios";
import Notification from "./Notification.vue";

export default {
  components:{
    Notification,
  },
  props: {
    show: Boolean,
    artId: Number
  },
  data() {
    return {
      file: {},
      image: "",
      fileName: "",
      attributionText: "",
      attributionURL: "",
      loading: false,
      showNotification: false,
    };
  },
  methods: {
    handleFileUpload({ target: { files } }) {
      const [file] = files;

      this.fileName = file.name;
      this.file = file;

      const image = new Image();
      const reader = new FileReader();
      const vm = this;

      reader.onload = e => {
        this.image = e.target.result;
      };

      reader.readAsDataURL(file);
    },
    async handleSubmit() {
      this.loading = true;
      const formData = new FormData();
      formData.append("art_id", this.artId);
      formData.append("image", this.file, this.fileName);
      formData.append("attribution_text", this.attributionText || 'Anonymous');
      formData.append("attribution_url", this.attributionURL);
      formData.append("primary", "false");

      const uploadedPhoto = await axios
        .post("/api/photos.json", formData)
        .then(res => res.data);
      this.loading = false;
      this.$emit("uploadedPhoto", uploadedPhoto);
      this.showNotification = true;
      // this.close();
    },
    close() {
      this.$emit("close");
      this.file = {};
      this.image = '';
      this.fileName = '';
      this.attributionText = '';
      this.attributionURL = '';
      this.loading = false;
      this.showNotification = false;
    }
  },
  computed: {
    showUploadDialog() {
      return this.show;
    },
    disableSubmitButton() {
      return !this.fileName;
    }
  }
};
</script>
<style scoped>
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

.close {
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-left: 23px;
}
</style>