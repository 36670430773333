/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import "@trevoreyre/autocomplete-vue/dist/style.css";
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import "../assets/css/index.css"
import "vue-select/dist/vue-select.css";
import 'animate.css/animate.min.css';

import * as VueGoogleMaps from "vue2-google-maps";

import App from "../app.vue";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import Lightbox from "../assets/vue-easy-lightbox.common.min";
import VModal from 'vue-js-modal'
import Vue from "vue";
import VueRouter from "vue-router";
import { router } from "../router";
import vSelect from "vue-select";
import GmapCluster from 'vue2-google-maps/dist/components/cluster' // replace src with dist if you have Babel issues

Vue.use(Lightbox);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCVG5JC8HYfgnx1WipC9Mjgs2HL09IG48E",
    libraries: 'places'
  }
});

Vue.use(Autocomplete);
Vue.use(VueRouter);
Vue.use(VModal);

Vue.component('GmapCluster', GmapCluster)
Vue.component("v-select", vSelect);

//MOCKS
//Get arts on search page https://5c9b964b5ee0830014b71809.mockapi.io/arts
//Get art on about page https://5c9b964b5ee0830014b71809.mockapi.io/art
//Get more info on about page https://5c9b964b5ee0830014b71809.mockapi.io/more
//Get comment on about page https://5c9b964b5ee0830014b71809.mockapi.io/comments
//All this you can take by id, just add enpoint/:id
//https://5c9b964b5ee0830014b71809.mockapi.io/art/1

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    render: h => h(App),
    router
  }).$mount();
  document.body.appendChild(app.$el);
});

// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>

// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })
