<template>
  <div v-infinite-scroll="loadMore"
       infinite-scroll-disabled="busy"
       infinite-scroll-distance="10"
       infinite-scroll-throttle-delay="200"
  >
    <div class="search-page">
      <autocomplete
        :search="search"
        :default-value="$route.query.city"
        placeholder="Search for a city"
        @submit="onSubmit"
        v-on:keyup.enter="handleEnter"
        class="w-full sm:w-full md:w-1/2 px-2 my-4"
      ></autocomplete>
      <div class="flex w-full my-4 sm:mt-auto flex-wrap">
        <v-select
          class="text-lg text-gray-500 py-2 px-2 w-full md:w-1/3"
          aria-placeholder="Artists"
          placeholder="ARTISTS"
          :select-on-key-codes="[188, 13]"
          select-on-tab
          v-model="artist"
          label="Artists"
          :options="artists"
        />
        <v-select
          class="text-lg text-gray-500 px-2 py-2 w-full md:w-1/3"
          aria-placeholder="Artists"
          placeholder="TAGS"
          :select-on-key-codes="[188, 13]"
          select-on-tab
          v-model="tag"
          label="Artists"
          :options="tags"
        />
        <v-select
          class="text-lg text-gray-500 px-2 py-2 w-full md:w-1/3"
          aria-placeholder="Artists"
          placeholder="CATEGORIES"
          :select-on-key-codes="[188, 13]"
          select-on-tab
          v-model="category"
          label="Artists"
          :options="categories"
        />
      </div>
    </div>
    <div class="search-info" v-if="!loading && !error">
      <div class="result" v-if="city">
        <div>{{ items.length }} results for</div>
        <div class="strong">
          <i>"{{ city }}"</i>
        </div>
      </div>
      <div class="text-sm">
        <div class="w-full flex flex-wrap">
          <div class="w-full sm:w-auto my-4 sm:my-auto">
            <span>SORT BY: </span>
            <select
              v-model="sort"
              class="select inline mx-2"
              style="font-weight: bold; margin-left: -15px"
            >
              <option value="most-recent">Most Recent</option>
              <option value="location">By Location</option>
            </select>
          </div>
          <div class="w-full sm:w-auto">
            <span>Show Map: </span>
            <span
              class="toggle-wrapper inline"
              role="checkbox"
              tabindex="0"
              @click="showMap = !showMap"
              :aria-checked="!showMap"
            >
            <span class="toggle-background" :style="backgroundStyles"></span>
            <span class="toggle-indicator" :style="indicatorStyles">
              <svg
                v-if="showMap"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#028388"
                viewBox="0 0 24 24"
              >
                <path fill="none" d="M0 0h24v24H0z"/>
                <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
              </svg>
            </span>
          </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading">
      <div class="loader">Loading...</div>
    </div>
    <div class="error" v-else-if="error">
      <i class="fa fa-exclamation-circle"></i>
      {{ error }}
    </div>
    <div class="w-full my-2" v-else>
      <div class="grid-container" v-if="items.length > 0">
        <div :class="{'animate__animated animate__fadeInDown': showMap}" class=" map w-full" v-if="showMap">
          <GmapMap
            :center="center"
            :zoom="zoom"
            map-type-id="terrain"
            style="width: 100%; height: 100%"
          >
            <GmapCluster :minimumClusterSize="3" :zoomOnClick="true">
              <GmapMarker
                :key="index"
                :clickable="true"
                :icon="{
                url: require('../assets/red.png'),
                scaledSize: {width: 30, height: 45, f: 'px', b: 'px',},
                }"
                v-for="(item, index) in items"
                @click="clickOnArtMarker(item)"
                :position="{ lat: item.lat, lng: item.lng }"

              />
            </GmapCluster>
            <GmapInfoWindow
              style="text-align: center"
              :options="optionsInfoWindow"
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen=false">
              <router-link
                v-if="art"
                :to="`/art/${art.friendly_id || art.id}`"
                v-slot="{ href, route, navigate, isActive, isExactActive }"
              >
                <a :href="href" @click="redirect(art.friendly_id || art.id)" class="text-lg font-medium"
                >{{ artPopUpContent }}

                  <img
                    v-if="art && getPrimaryPhoto(art)"
                    :src="getPrimaryPhoto(art).image_url"
                    alt="image"
                    class="h-56 rounded-t-lg md:w-48 object-cover"/>
                </a>
              </router-link>
            </GmapInfoWindow>
          </GmapMap>
        </div>
        <div v-for="item in items"
             :key="item.id"
             @click="redirect(item.friendly_id || item.id)"
             :class="{'animate__animated animate__backInUp': !showMap}"
             class="w-full mt-2 px-1 cursor-pointer">
          <img
            v-if="item && getPrimaryPhoto(item)"
            :src="getPrimaryPhoto(item).image_url"
            alt="image"
            class="h-56 rounded-t-lg w-full object-cover"/>
          <div class="bg-gray-200 text-center font-bold text-lg leading-tight rounded-b-lg py-2 px-2 w-full">
            <div class="py-1">{{ item.title }}</div>
            <div class="font-thin text-md" style="color: #999">
              by
              <span style="color: #4860a0">
                {{ item.artists.length > 0 ? item.artists.map(item => item.name).join(", ") : "Unknown" }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import infiniteScroll from 'vue-infinite-scroll';
  import artAroundApi from '../api'
  import {getUniqueListBy} from "../features/utils";
  import {getCurrentPosition} from "../features/geolocation";

  export default {
    directives: {infiniteScroll},
    data() {
      return {
        busy: false,
        art: null,
        artPopUpContent: "",
        infoWinOpen: false,
        infoWindowPos: null,
        optionsInfoWindow: {
          pixelOffset: {
            width: 0,
            height: -35
          }
        },
        page: 1,
        data: [],
        defaultLocation: {
          lat: 41.8781136,
          lng: -87.6297982
        },
        cities: ["New York", "Chicago"],
        sort: this.$route.query.sort || "most-recent",
        artist: this.$route.query.artist || "",
        category: this.$route.query.category || "",
        tag: this.$route.query.tag || "",
        artists: [],
        categories: [],
        tags: [],
        items: [],
        loading: false,
        error: "",
        center: {},
        city: "",
        showMap: false,
        userLocation: false,
        notMore: false,
      };
    },
    async mounted() {
      window.scrollTo(0, 0);

      artAroundApi.getAllCities().then((cities) => {
        this.cities = [...new Set(cities)];
      })
      artAroundApi.getAllTags().then((tags) => {
        this.tags = [...new Set(tags)]
      })
      artAroundApi.getAllArtists().then((artists) => {
        this.artists = [...new Set(artists)]
      })
      artAroundApi.getAllCategories().then((categories) => {
        this.categories = [...new Set(categories)]
      })
      this.fetchUserLocation();

      if (this.$route.query.sort && this.$route.query.sort === 'location') {
        try {
          const pos = await getCurrentPosition();
          const {
            coords: {latitude, longitude}
          } = pos;
          this.defaultLocation = {
            lat: latitude,
            lng: longitude
          };
          this.userLocation = true;
          await this.initialLoad();
          this.updateSearchClasses();

        } catch (_) {
          this.sort = 'most-recent';
          await this.$router.push({
            path: '',
            query: {
              ...this.$route.query,
              sort: 'most-recent',
            },
          }).catch((err) => {
          })
          await this.initialLoad()
          this.updateSearchClasses();
        }
      } else {
        await this.initialLoad()
        this.updateSearchClasses();
      }

    },
    watch: {
      $route(to, from) {
        this.getArts(this.$route.query, 1, this.defaultLocation).then(({arts: arts, total: total}) => {
          if (!total) {
            this.items = [];
          }
          if (!arts || !arts.length) {
            this.notMore = true;
          } else {
            this.items = [...arts];
            this.page = 1;
            this.center = {
              lat: arts[0].lat || this.defaultLocation.lat,
              lng: arts[0].lng || this.defaultLocation.lng,
            }
          }
        })
      },
      tag: {
        handler: function (value, _) {
          this.$router.push({
            path: '',
            query: {
              ...this.$route.query,
              sort: this.sort,
              category: this.category ? this.category : undefined,
              tag: this.tag ? this.tag : undefined,
              artist: this.artist ? this.artist : undefined,
            },
          });
        },
      },
      category: {
        handler: function (value, _) {
          this.$router.push({
            path: '',
            query: {
              ...this.$route.query,
              sort: this.sort,
              category: this.category ? this.category : undefined,
              tag: this.tag ? this.tag : undefined,
              artist: this.artist ? this.artist : undefined,
            }
          });
        },
      },
      artist: {
        handler: function (value, _) {
          this.$router.push({
            path: '',
            query: {
              ...this.$route.query,
              sort: this.sort,
              category: this.category ? this.category : undefined,
              tag: this.tag ? this.tag : undefined,
              artist: this.artist ? this.artist : undefined,
            }
          });
        },
      },
      items: {
        handler: function (value, _) {
          const lookup = this.items.reduce((a, e) => {
            a[e.id] = ++a[e.id] || 0;
            return a;
          }, {});

          const newItems = getUniqueListBy(this.items, 'id');
          if (newItems.length !== this.items.length) {
            this.items = [...newItems];
          }
        },
        deep: true
      },
      sort: {
        handler: function (value, _) {
          if (value === 'location') {
            if (this.userLocation) {
              this.$router.push({
                path: '',
                query: {
                  ...this.$route.query,
                  sort: value,
                  category: this.category ? this.category : undefined,
                  tag: this.tag ? this.tag : undefined,
                  artist: this.artist ? this.artist : undefined,
                }
              });
            } else {
              this.fetchUserLocation()
              alert("ArtAround cannot access your current location check your permission settings")
              this.sort = 'most-recent';
            }
          } else {
            this.$router.push({
              path: '',
              query: {
                ...this.$route.query,
                sort: value,
                category: this.category ? this.category : undefined,
                tag: this.tag ? this.tag : undefined,
                artist: this.artist ? this.artist : undefined,
              }
            }).catch((_) => {
            })
          }
        },
      },
    },
    computed: {
      backgroundStyles() {
        return {
          backgroundColor: this.showMap ? "#028388" : "#dae1e7"
        };
      },
      indicatorStyles() {
        return {
          transform: this.showMap ? "translateX(2rem)" : "translateX(1rem)"
        };
      },
      zoom() {
        if (this.city && this.items.length) {
          return 10;
        }
        return this.items.length ? 4 : 10;
      }
    },
    methods: {
      getPrimaryPhoto(art) {
        if (!art.photos || !art.photos.length){
          return null
        }
        const mainPhoto = art.photos.find((photo) => !!photo.primary)

        if (mainPhoto) {
          return mainPhoto
        }
        return art.photos[0]
      },
      clickOnArtMarker: async function (art) {
        this.art = art;
        this.infoWindowPos = {lat: art.lat, lng: art.lng};
        this.infoWinOpen = true;
        this.artPopUpContent = art.title;
      },
      updateSearchClasses() {
        const resultList = document.querySelector('#autocomplete-result-list-1')
        resultList.style.zIndex = 10;
      },
      loadMore: async function () {
        this.busy = true;
        if (!this.loading && !this.notMore) {
          //const storedCity = localStorage.getItem("city");
          const {arts: arts, total: total} = await this.getArts(this.$route.query, this.page, this.defaultLocation)
          if (!total) {
            this.items = [];
          }
          if (!arts || !arts.length) {
            this.notMore = true;
          } else {
            // this.items.push(...arts)
            const newArray = this.items.filter(val => !arts.includes(val));
            this.items = [...newArray, ...arts]

            this.page++
          }
        }
        this.busy = false;
      },
      fetchUserLocation() {
        const position = navigator.geolocation.getCurrentPosition(pos => {
          const {
            coords: {latitude, longitude}
          } = pos;
          this.defaultLocation = {
            lat: latitude,
            lng: longitude
          };
          this.userLocation = true;
        });
      },
      redirect(id) {
        this.$router.push({
          path: `/art/${id}`
        });
      },
      search(input) {
        if (!input) {
          return this.cities;
        }

        return this.cities.filter(city =>
          city.toLowerCase().startsWith(input.toLowerCase())
        );
      },
      onSubmit(result) {
        if (result) {
          this.page = 1;
          this.notMore = false;
          this.city = result;
          this.$router.push({
            path: '',
            query: {
              ...this.$route.query,
              sort: this.sort,
              city: result
            },
          });
          //const storedCity = localStorage.getItem("city");
          //this.getRequest(result, this.colors, this.decades, this.places, true);
        }
      },
      handleEnter(evt) {
        const {
          target: {value}
        } = evt;
        this.notMore = false;
        this.city = value;
        this.$router.push({
          path: '/',
          query: {
            ...this.$route.query,
            sort: this.sort,
            city: value
          },
        });

        // this.getRequest(value, this.colors, this.decades, this.places, true);
      },
      async getCurrentPosition(city) {
        try {
          const {data: center} = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${city ||
            "USA"}&key=AIzaSyCVG5JC8HYfgnx1WipC9Mjgs2HL09IG48E`
          );
          return center && center.results && center.results.length && center.results[0] && center.results[0].geometry
            ? center.results[0].geometry.location
            : {lat: 0, lng: 0}
        } catch (_e) {
          return {lat: 0, lng: 0}
        }
      },
      assignLatLng(defaultPosition) {
        return function (item) {
          return {
            ...item,
            lat: item.lat || defaultPosition.lat,
            lng: item.lng || defaultPosition.lng
          }
        }
      },
      async getArts(params, page, defaultPosition) {
        try {
          const {year, artist, tag, category, city, sort} = params;
          if (year) {
            const {arts = [], total = 0} = await artAroundApi.getArtsByYear(year, {page: page})
            return {arts: arts.map(this.assignLatLng(defaultPosition)), total: total}
          } else {
            const {arts, total} = await artAroundApi.getArts({
              page: page,
              city: city,
              artist: artist,
              tag: tag,
              category: category,
              sort: sort,
              lat: this.userLocation ? this.defaultLocation.lat : 0,
              lng: this.userLocation ? this.defaultLocation.lng : 0
            })
            return {arts: arts.map(this.assignLatLng(defaultPosition)), total: total}
          }
        } catch (e) {
          console.error(e)
          return {arts: [], total: 0}
        }
      },
      async initialLoad() {
        // Pre loading
        this.page = 1;
        this.loading = true;
        const {year, artist, tag, category, city} = this.$route.query;
        try {
          const defaultPosition = this.getCurrentPosition()

          const {arts: arts, total: _} = await this.getArts({...this.$route.query, sort: this.sort}, 1, defaultPosition)

          this.items = arts;

          this.center = arts && arts.length
            ? {lat: arts[0].lat, lng: arts[0].lng}
            : this.defaultLocation;
          this.page++
          this.city = city
          this.showMap = true;
        } catch (e) {
          console.error(e)
          this.items = [];
          this.center = this.defaultLocation
        } finally {
          this.loading = false;
        }

      },
    }
  };
</script>

<style scoped>
  .search-page {
    width: 100%;
    background: #f8f8f8;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
  }

  .select {
    border: none;
    font-size: 1.1rem;
    font-weight: 300;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 13px;
    margin-right: 2rem;
    padding: 1rem;
    padding-right: 2rem;
    outline: none;
    cursor: pointer;
  }

  .search-info {
    font-size: 1.2rem;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 95%;
    padding: 0 2.5%;
  }

  .result {
    margin-top: 10px;
    font-weight: 300;
    display: flex;
  }

  .strong {
    margin-left: 5px;
    font-weight: bold;
  }

  .toggle-wrapper {
    margin-left: 10px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 2rem;
    width: 3rem;
    border-radius: 9999px;
  }

  .toggle-wrapper:focus {
    outline: 0;
  }

  .toggle-background {
    display: inline-block;
    border-radius: 9999px;
    height: 100%;
    width: 100%;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease;
  }

  .toggle-indicator {
    display: flex;
    font-size: 0.8rem;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0.25rem;
    left: -0.75rem;
    height: 1.5rem;
    width: 1.5rem;
    background-color: #fff;
    border-radius: 9999px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }

  .map {
    grid-area: map;
    height: 26rem;
  }

  .grid-container {
    display: grid;
    grid-template-areas: 'map'
                       '.';
    grid-gap: 10px;
    grid-template-rows: auto;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 640px) {
    .map {
      height: 38rem;
    }

    .grid-container {
      grid-template-areas: 'map map'
					   '. .';
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (min-width: 768px) {
    .grid-container {
      grid-template-areas: 'map map .'
					    'map map .'
					    '. . .';
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (min-width: 1024px) {
    .grid-container {
      grid-template-areas: 'map map map .'
					    'map map map .'
					    '.   .   .   .';
      grid-template-columns: repeat(4, 1fr);
    }
  }

</style>
