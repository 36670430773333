<template>
  <input :id="id"
         :required="required"
         :class="classes"
         :placeholder="placeholder"
         ref="searchField"/>
</template>
<script>
  import {gmapApi} from 'vue2-google-maps'
  export default {
    props: {
      fields: {
        type: Array,
        default: function () {
          return [
            'geometry',
            'formatted_address',
            'address_components',
            'adr_address',
            'formatted_address',
            'html_attributions',
            'name',
            'types',
            'vicinity'
          ]
        }
      },
      classes: String,
      id: String,
      placeholder: String,
      required: {
        type: Boolean,
        default: true,
      }
    },
    data: function () {
      return {
        place: {},
      }
    },
    watch: {
      place(currentPlace) {
        this.$emit('placeChange', currentPlace)
      },
      google(){
        this.google &&
        this.initializeAutocomplete();
      }
    },
    computed:{
      google: gmapApi,
    },
    mounted() {
      setTimeout(() => {
        this.initializeAutocomplete()
      }, 500);
    },
    methods: {
      initializeAutocomplete() {
        const input = this.$refs.searchField;
        const autocomplete = new this.google.maps.places.Autocomplete(input, {types: ['geocode'], fields: this.fields});
        const el = this;
        autocomplete.addListener('place_changed', function () {
          const place = autocomplete.getPlace();
          el.place = Object.assign({}, place);
        });
      },
    }
  }
</script>