<template>
  <header
    class="w-full flex flex-wrap font-sans uppercase mt-14"
    style="font-size: 12px"
  >
    <div class="w-full flex flex-wrap justify-between">
      <a href="https://artaround.org/" class="-mt-5">
        <img
          src="../../assets/artaround-logo.png"
          style="min-height: 75px; width: 200px;  no-repeat !important"
        />
      </a>
      <div class="block lg:hidden -mt-2">
        <button
          id="nav-toggle"
          @click="toggle = !toggle"
          class="flex items-center px-3 py-2 border rounded text-gray-800 border-none text-2xl focus:outline-none"
        >
          <div v-show="!toggle"><i class="fas fa-bars text-gray-700"></i></div>
          <div v-show="toggle">
            <i class="fa fa-times text-gray-700"></i>
          </div>
        </button>
      </div>
    </div>
    <nav class="flex items-center justify-end w-full mt-8">
      <div
        class="w-full flex-grow tracking-wider"
        id="nav-content"
        style="font-size: 15px; font-family: 'Open Sans', 'Helvetica', arial"
      >
        <ul
          class="list-reset lg:flex justify-end flex-1 text-left mb-12"
          :class="{ hidden: !toggle }"
          style="color: #12302e"
        >
          <li class="relative mb-3">
            <button
              type="button"
              class="w-full px-4 tracking-wider hover:text-red-500 text-left outline-none focus:outline-none uppercase"
              @click="selected !== 1 ? (selected = 1) : (selected = null)"
            >
              <div class="flex items-center justify-between">
                <a href="https://map.artaround.org/">Map</a>
                <li class="fa fa-chevron-down"></li>
              </div>
            </button>

            <div
              class="relative overflow-hidden transition-all max-h-0 duration-500"
              style=""
              ref="container1"
              :style="
                selected == 1
                  ? 'max-height: ' + $refs.container1.scrollHeight + 'px'
                  : ''
              "
            >
              <div class="pl-8 pt-2 text-left">
                <ul>
                  <li class="hover:text-red-500 py-5">
                    <a href="https://map.artaround.org">Feed</a>
                  </li>
                  <li class="hover:text-red-500">
                    <a href="https://artaround.org/guides">Guides</a>
                  </li>
                  <li class="hover:text-red-500 py-5">
                    <a href="https://artaround.org/collections">Collections</a>
                  </li>
                  <li class="hover:text-red-500">
                    <a href="https://artaround.org/publications">Publications</a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li class="relative mb-3">
            <button
              type="button"
              class="w-full px-4 tracking-wider text-left outline-none focus:outline-none uppercase mt-2"
              @click="selected !== 2 ? (selected = 2) : (selected = null)"
            >
              <div class="flex items-center justify-between">
                <a class="hover:text-red-500" href="https://artaround.org/community/">Community</a>
                <li class="fa fa-chevron-down"></li>
              </div>
            </button>

            <div
              class="relative overflow-hidden transition-all max-h-0 duration-500"
              style=""
              ref="container2"
              :style="
                selected == 2
                  ? 'max-height: ' + $refs.container2.scrollHeight + 'px'
                  : ''
              "
            >
              <div class="pl-8 pt-2 text-left">
                <ul>
                  <li class="hover:text-red-500 py-5">
                    <a href="https://artaround.org/ambassadors">Ambassadors</a>
                  </li>
                  <li class="hover:text-red-500">
                    <a href="https://artaround.org/partners">Partners</a>
                  </li>
                  <li class="hover:text-red-500 pt-5">
                    <a href="https://artaround.org/supporters">Supporters</a>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li class="mr-3 mb-3" @mouseover="selected = null">
            <a
              class="inline-block py-2 px-4 no-underline hover:text-red-500"
              href="https://artaround.org/news"
              >News</a
            >
          </li>

          <li class="mr-3 mb-3" @mouseover="selected = null">
            <a
              class="inline-block py-2 px-4 no-underline hover:text-red-500"
              href="https://arrow.artaround.org/"
              >Magazine</a
            >
          </li>
          <li class="mr-3 mb-3" @mouseover="selected = null">
            <a
              class="inline-block py-2 px-4 no-underline hover:text-red-500"
              href="https://artaround.org/about"
              >About</a
            >
          </li>
          <button
            @click="uploadArt()"
            class="w-100 mx-4 px-2 py-2 tracking-wider text-left outline-none focus:outline-none uppercase mt-2 bg-red-500 text-white rounded-lg"
          >
            Upload Art
          </button>
        </ul>
      </div>
    </nav>
  </header>
</template>
<script>
export default {
  data: () => {
    return {
      toggle: false,
      selected: null,
    };
  },
  methods: {
    resetMobileMenu() {
      if (!matchMedia("(max-width:768px)").matches) {
        this.toggle = false;
      }
    },
    uploadArt(){
      this.$emit('uploadArt');
    }
  },
  mounted() {
    window.addEventListener("resize", this.resetMobileMenu);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resetMobileMenu);
  },
};
</script>