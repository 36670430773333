<template>
  <main class="max-w-screen-2xl mx-auto">
    <artwork-wizard-uploader
      v-if="openStepper"
      :show-modal="openStepper"
      @close="closeStepperDialog"
    />
    <div class="w-full hidden md:block md:px-16 xl:px-48">
      <DesktopNavbar @uploadArt="showStepperDialog" />
    </div>
    <div
      class="w-full block md:hidden"
      style="padding-left: 4.9%; padding-right: 4.9%"
    >
      <MobileNavbar  @uploadArt="showStepperDialog" />
    </div>
    <div class="w-full px-2 sm:px-6 pt-0 sm:pt-12">
      <router-view />
    </div>
  </main>
</template>

<script>
import DesktopNavbar from "../components/navigation/DesktopNavbar.vue";
import MobileNavbar from "../components/navigation/MobileNavbar.vue";
import ArtworkWizardUploader from "../components/ArtworkWizardUploader";

export default {
  name: "LayoutBase",
  components: {
    DesktopNavbar,
    MobileNavbar,
    ArtworkWizardUploader,
  },
  data: () => {
    return {
      openStepper: false,
    };
  },
  methods: {
    showStepperDialog: function () {
      this.openStepper = true;
      // this.$modal.show("upload-stepper");
    },
    closeStepperDialog: function () {
      this.openStepper = false;
      // this.$modal.hide("upload-stepper");
    },
  },
};
</script>