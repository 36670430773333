<template>
  <div>
    <div v-if="showModal"
         class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="relative w-auto my-6 mx-2 max-w-3xl">
        <!--content-->
        <div
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
            <h3 class="text-3xl font-semibold">
              Add a flag for this artwork
            </h3>
            <button
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              v-on:click="closeModal()">
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <div class="my-4">
              <input v-model="name" placeholder="Anonymous" class="comments__input_field my-4"/>
              <input v-model="email" placeholder="Enter your email (optional)" class="comments__input_field my-4"/>
              <textarea v-model="comment"
                        ref="comment"
                        style="min-height: 116px"
                        placeholder="Comment"
                        required
                        class="comments__input_field"/>
            </div>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
            <button
              class="text-red-600 bg-transparent border border-solid border-red-600 hover:bg-red-600 hover:text-white active:bg-red-600 font-bold uppercase px-6 py-3 rounded-xl outline-none focus:outline-none mr-1 mb-1"
              type="button" style="transition: all .15s ease" v-on:click="closeModal()">
              Cancel
            </button>
            <button
              class="py-3 mb-1 px-6 focus:outline-none rounded-xl bg-red-600 text-white hover:text-blue-800 font-bold uppercase"
              type="button"
              style="transition: all .15s ease"
              v-on:click="sendFlagData()">
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
  export default {
    props: {
      showModal: Boolean,
    },
    data() {
      return {
        name: '',
        email: '',
        comment: '',
      }
    },
    methods: {
      clear() {
        this.name = '';
        this.email = '';
        this.comment = '';
      },
      closeModal() {
        this.clear();
        this.$emit('closeModal');
      },
      sendFlagData() {
        const commentRef = this.$refs.comment;
        if(commentRef && commentRef.checkValidity()){
          this.$emit('save', {
            name: this.name || 'anonymous',
            email: this.email || 'anonymous@email.com',
            comment: this.comment,
          });
        } else{
          commentRef.reportValidity();
        }
      },
    }
  }
</script>