import axios from "axios";

export const getCurrentPosition = (options) => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
    })
}

export const getDefaultUrl = (key) => {
    let url = 'https://maps.googleapis.com/maps/api/geocode/json';
    url += "?key=" + encodeURIComponent(key);
    return url;
}

export const getAddressFromLatLng = async (latLngObj) => {
    let url = getDefaultUrl("AIzaSyCVG5JC8HYfgnx1WipC9Mjgs2HL09IG48E");

    url += "&latlng=" + encodeURIComponent(latLngObj.lat) + "," + encodeURIComponent(latLngObj.lng);

    const {data} = await axios.get(url)
    console.log(data)
    return data;
}
