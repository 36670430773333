<template>
  <div class="page__container">
    <div class="loader mt" v-if="loading">Loading...</div>
    <div class="error mt" v-else-if="error">
      <i class="fa fa-exclamation-circle"></i>
      {{ error }}
    </div>
    <div v-else>
      <VueEasyLightbox
        @on-prev-click="increaseTextDescription"
        @on-next-click="increaseTextDescription"
        :visible="visible"
        :imgs="carousel"
        :index="index"
        @hide="handleHide"
      >
        <template v-slot:title="{ item }">
          <div style="display: flex; flex-direction: column">
            <span class="my-1">
              Photo by {{ item.title }}
            </span>
            <a
              v-if="item.attribution_url"
              :href="validURL(item.attribution_url)"
              style="text-decoration: underline"
              class="my-1"
              target="_blank"
            >
              Visit Website
            </a>
            <span v-if="item.submitted_at" class="my-1">
              {{ humanDate(item.submitted_at) }}
            </span>
          </div>
        </template>
      </VueEasyLightbox>
      <div class="w-full">
        <div class="flex flex-wrap flex flex-row">
          <div class="w-full md:w-1/2 lg:w-1/2 px-2 py-1">
            <img
              :src="imgs[0].image_url"
              v-if="!!imgs[0]"
              class="object-cover h-80"
              style="width: 100%; border-radius: 5px; cursor: pointer"
              @click="showImage(0)"
            />
          </div>
          <div
            v-for="(img, index) in imgs.slice(1, 7)"
            :key="img.id"
            class="hidden sm:block sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 py-1"
          >
            <img
              :src="img.image_url"
              v-if="img"
              class="h-40 sm:h-56 md:h-80 object-cover w-full"
              style="width: 100%; border-radius: 5px; cursor: pointer"
              @click="showImage(index + 1)"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <div class="my-4 mx-6">
          <button class="img-btn" @click="showMultiple">View photos</button>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full md:w-1/2">
          <div class="w-full">
            <div class="text-lg md:text-3xl font-bold">{{ item.title }}</div>
            <div class="text-lg md:text-xl flex">
              <div class="w-full">
                <div class="flex text-md md:text-lg pr-2" style="color: #999">
                  by
                  <div style="color: #4860a0; margin-left: 5px">
                    <router-link
                      v-for="(artist, index) of item.artists"
                      :key="artist.id"
                      style="text-decoration: none"
                      :to="`/?artist=${artist.name}`"
                    >{{
                      artist.name
                      }}{{ index === item.artists.length - 1 ? "" : "," }}
                    </router-link>
                  </div>
                </div>
                <div class="flex flex-row mt-8">
                  <div class="w-full">
                    <div class="flex flex-wrap w-full flex-row">
                      <a
                        class="visit my-2 w-1/2 md:w-auto md:mx-1"
                        target="_blank"
                        :href="item.website"
                        v-if="item.website"
                      >
                        <i class="fa fa-check"></i> Visit website
                      </a>
                      <a class="visit my-2 w-1/2 md:w-auto md:mx-1" @click="showUploadDG = true">
                        <i class="fa fa-plus"></i> Add photos
                      </a>
                      <ShareDropdown :sharedURL="shareURL"/>
                      <a class="visit my-2 w-1/2 md:w-auto md:mx-1" @click="openArtworkFlagDialog">
                        <i class="fa fa-flag"></i> Flag
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <div class="text-md md:text-lg mt30">
              <div class="info-item">
                <strong>City:</strong>
                <router-link :to="`/?city=${item.city}`">
                  {{ item.city }}
                </router-link>
              </div>
              <div class="info-item">
                <strong>Year:</strong>
                <router-link :to="`/?year=${item.year}`">{{
                  item.year
                  }}
                </router-link>
              </div>
              <div class="info-item">
                <strong>Categories:</strong>
                <template v-for="(tag, index) in item.categories">
                  <router-link :to="`/?category=${tag}`">
                    {{ tag }}
                  </router-link>
                  <span v-if="item.categories.length - 1 != index" class="-ml-1"
                  >,</span
                  >
                </template>
              </div>
              <div class="info-item">
                <strong>Tags:</strong>
                <template
                  v-for="(tag, index) in item.tags"
                  class="text-md md:text-lg"
                >
                  <router-link :to="`/?tag=${tag}`">
                    {{ tag }}
                  </router-link>
                  <span v-if="item.tags.length - 1 != index" class="-ml-1"
                  >,</span
                  >
                </template>
              </div>
              <div class="info-item" v-if="item.commissioners && item.commissioners.length">
                <strong>Commissioners:</strong>
                <template v-for="(commissioner, index) in item.commissioners">
                  <a>
                    {{ commissioner.name }}
                  </a>
                  <span
                    v-if="item.commissioners.length - 1 != index"
                    class="-ml-1"
                  >,</span
                  >
                </template>
              </div>
            </div>

            <div class="info-item about" v-if="item.description">
              <div class="text-md md:text-lg font-bold text-black">About:</div>
              {{ humanDescription(item.description) }}
            </div>
            <div class="map-component block md:hidden my-6">
              <GmapMap
                :center="{ lng: item.lng, lat: item.lat }"
                :zoom="6"
                map-type-id="terrain"
                style="width: 100%; height: 100%"
              >
                <GmapMarker
                  :position="{ lng: item.lng, lat: item.lat }"
                  :clickable="false"
                  :draggable="false"
                  :icon="{
                  url: require('../assets/red.png'),
                  scaledSize: { width: 30, height: 45, f: 'px', b: 'px' },
                }"
                />
              </GmapMap>
            </div>
            <div
              class="w-full rounded-lg bg-gray-100 px-2 py-4 block md:hidden"
              v-if="item.location_description"
            >
              <div class="flex w-full justify-between text-xl font-semibold">
                <div><i class="fas fa-map-marker-alt mt-1 mx-2"></i> Description:</div>
              </div>
              <div class="flex w-full text-lg font-thin">
                {{ item.location_description }}
              </div>
            </div>
            <a class="w-full flex justify-end loc-link block md:hidden" :href="item.flagLink">Get directions</a>
            <div class="info-item" v-if="relatedLinks.length">
              <div class="text-md md:text-lg font-bold text-black">
                This artwork elsewhere:
              </div>
              <ul class="ml-8" style="list-style: unset">
                <template v-for="relatedLink in relatedLinks">
                  <li v-if="relatedLink.title">
                    <a
                      class="visit ml"
                      style="color: #4860a0; text-decoration: underline"
                      target="_blank"
                      :href="normalizeHttpProtocol(relatedLink.url)"
                    >
                      {{ relatedLink.title }}
                    </a>
                  </li>
                </template>

              </ul>
            </div>

            <div class="comments">
              <div class="total">Thoughts?</div>
              <div class="flex flex-wrap text-lg py-4">
                <div class="w-full px-3">
                  <label
                    class="comments__input_label"
                    for="name"
                  >Name</label
                  >
                  <input
                    class="comments__input_field"
                    id="name"
                    type="text"
                    v-model="name"
                    placeholder="Anonymous"
                  />
                </div>
                <div class="w-full px-3 mt-2">
                  <label
                    class="comments__input_label"
                    for="comment"
                  >Comment</label
                  >
                  <input
                    id="comment"
                    v-model="value"
                    class="comments__text_field"
                    placeholder="Add a comment..."
                    v-on:keyup.enter="addComment"
                  />
                </div>
              </div>
              <div>
                <div class="loader" v-if="loadingComments">Loading...</div>
                <div
                  v-else
                  class="comment mt30"
                  v-for="comment in comments"
                  :key="comment.id"
                >
                  <img
                    class="avatar"
                    src="https://image.flaticon.com/icons/png/512/64/64572.png"
                    alt="alt"
                  />
                  <div class="comment-item">
                    <div class="comment-name">
                      {{ comment.name }}
                      <div class="on">
                        {{
                        new Date(comment.createAt).toLocaleString("en", {
                        month: "2-digit",
                        year: "numeric",
                        day: "2-digit",
                        hour: "numeric",
                        minute: "numeric",
                        })
                        }}
                      </div>
                    </div>
                    <div class="comment-message">{{ comment.body }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hidden md:block w-full md:w-1/2 ">
          <div class="map-component">
            <GmapMap
              :center="{ lng: item.lng, lat: item.lat }"
              :zoom="6"
              map-type-id="terrain"
              style="width: 100%; height: 100%"
            >
              <GmapMarker
                :position="{ lng: item.lng, lat: item.lat }"
                :clickable="false"
                :draggable="false"
                :icon="{
                  url: require('../assets/red.png'),
                  scaledSize: { width: 30, height: 45, f: 'px', b: 'px' },
                }"
              />
            </GmapMap>
          </div>
          <div
            class="w-full rounded-lg bg-gray-100 px-2 py-4"
            v-if="item.location_description"
          >
            <div class="flex w-full justify-between text-xl font-semibold">
              <div><i class="fas fa-map-marker-alt mt-1 mx-2"></i> Description:</div>
            </div>
            <div class="flex w-full text-lg font-thin">
              {{ item.location_description }}
            </div>
          </div>
          <a class="w-full flex justify-end loc-link" :href="item.flagLink">Get directions</a>
        </div>
        <div class="w-full mt30 mb-8" v-if="images.length">
          <div class="more-title">Nearby Artworks</div>
          <div class="flex flex-wrap">
            <div
              @click="goToNearbyArtwork(item)"
              class="w-full cursor-pointer sm:w-1/2 md:w-1/3 lg:w-1/4 px-1"
              v-for="item in images"
              :key="item.id"
            >
              <img
                class="h-48 mt-2 object-cover w-full rounded-t-lg"
                v-if="item.photos && item.photos.length"
                :src="getPrimaryPhoto(item).image_url"
                alt="alt"
              />
              <div class="more-list-item-title">{{ item.title }}</div>
              <div class="more-list-item-subtitle">
                by
                <div class="name-item">
                  {{ item.artists.map((item) => item.name).join(", ") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <upload-photo
      :show="showUploadDG"
      @close="showUploadDG = false"
      @uploadedPhoto="uploadedPhoto"
      :artId="this.item.id"
    />
    <artwork-flag-dialog
      :show-modal="showFlagDialog"
      @closeModal="closeArtworkFlagDialog"
      @save="saveArtworkFlag"
    />
  </div>
</template>

<script>
  import UploadPhoto from "../components/UploadPhoto.vue";
  import ShareDropdown from "../components/ShareDropdown.vue";
  import ArtworkFlagDialog from "../components/ArtworkFlagDialog.vue";
  import VueEasyLightbox from "../assets/vue-easy-lightbox.common.min.js";
  import apiArtAround from '../api';
  import axios from "axios";
  import {format} from 'date-fns'
  import {getCurrentPosition} from "../features/geolocation";
  import api from '../api';
  import {sortPhotos} from "../features/arts";

  export default {
    components: {
      VueEasyLightbox,
      UploadPhoto,
      ShareDropdown,
      ArtworkFlagDialog,
    },
    async mounted() {
      window.scrollTo(0, 0);
      const artId = this.$route.params.id
      this.loadUserPosition();
      this.loadArt(artId);
      this.loadComments(artId);
    },
    computed: {
      artImages: function () {
        return this.imgs.slice(1, 5);
      },
      carousel: function () {
        return this.imgs.map((img) => {
          return {
            title: img.attribution_text,
            src: img.image_url,
            artists: this.item.artists,
            submitted_at: img.submitted_at,
            attribution_url: img.attribution_url,
          };
        });
      },
      relatedLinks: function () {
        if (!this.item || !this.item.links) {
          return [];
        }
        return this.item.links.map((link) => {
          if (typeof link !== "string") {
            return link;
          }
          return JSON.parse(link);
        });
      },
      shareURL: function () {
        return `${window.location.origin}/art/${this.$route.params.id}`;
      },
    },
    watch: {
      visible() {
        this.increaseTextDescription();
      },
    },
    data() {
      const artworkFlagInitialValue = {
        artId: null,
        lat: '',
        lng: '',
        email: '',
        name: '',
        comment: '',
      };
      return {
        loading: true,
        loadingComments: true,
        imgs: [],
        visible: false,
        index: 0,
        comments: [],
        images: [],
        value: "",
        name: "",
        email: "anonymous@mail.com",
        more: "",
        item: {},
        error: "",
        showUploadDG: false,
        location: {lat: 0, lng: 0},
        showFlagDialog: false,
        artworkFlag: {...artworkFlagInitialValue},
        artworkFlagInitialValue,
      };
    },
    methods: {
      loadArt(artId) {
        const isDraftMode = this.$route.path.includes('/preview');
        api.getArt(artId, isDraftMode).then((art) => {
          this.loadNearbyArts(artId, art.city || '')

          this.imgs = sortPhotos(art);
          this.item = art;

        }).catch((error) => {
          this.error = error.message;
        }).finally(() => {
          this.loading = false;
        })
      },
      humanDescription(description) {

        return description;
      },
      loadComments(artId) {
        api.getAllCommentsOfArt(artId).then((comments) =>{
          this.comments = comments;
        }).catch((error) => {
          this.error = error.message;
        }).finally(() => {
          this.loadingComments = false;
        })
      },
      async loadNearbyArts(artId, city) {
        const {
          data: {arts},
        } = await axios.get(`/api/arts.json?city=${city}&per=10`);

        this.images = arts.filter((art) => `${art.id}` !== `${artId}`);
      },
      loadUserPosition(){
        getCurrentPosition().then((pos) => {
          const {
            coords: {latitude, longitude},
          } = pos;
          this.location.lng = longitude;
          this.location.lat = latitude;
        })
      },
      humanDate(date){
        if(!date) {
          return '';
        }
        return format(new Date(date), 'PPPP');
      },
      getPrimaryPhoto(art) {
        if (!art.photos || !art.photos.length){
          return null
        }
        const mainPhoto = art.photos.find((photo) => !!photo.primary)
        if (mainPhoto) {
          return mainPhoto
        }
        return art.photos[0]
      },
      openArtworkFlagDialog() {
        this.resetFlagValues();
        this.showFlagDialog = true;
      },
      closeArtworkFlagDialog() {
        this.showFlagDialog = false;
        this.resetFlagValues();
      },
      resetFlagValues() {
        this.artworkFlag = {...this.artworkFlagInitialValue}
      },
      saveArtworkFlag(artworkFlag) {
        const artId = this.item.id;
        return apiArtAround.createFlagReport(artId, {
          lat: this.location.lat,
          lng: this.location.lng,
          name: artworkFlag.name,
          email: artworkFlag.email,
          comment: artworkFlag.comment,
        }).then(() => {
          this.closeArtworkFlagDialog();
        })
      },
      normalizeHttpProtocol(url) {
        if (!url) {
          return "#";
        }
        if (!url.match(/^http?:\/\//i)) {
          return "https://" + url;
        }
        return url;
      },
      showImage(index) {
        this.index = index;
        this.visible = true;
      },
      validURL(url) {
        if (!url.match(/^https?:\/\//i)) {
          return "http://" + url;
        }
        return url;
      },
      showMultiple() {
        this.show();
      },
      show() {
        this.index = 0;
        this.visible = true;
      },
      handleHide() {
        this.visible = false;
      },
      async addComment() {
        if (!this.value.trim()) return;
        const commentToSave = {
          name: this.name || "Anonymous",
          email: this.email,
          body: this.value,
          art_id: this.item.id,
        };

        const savedComment = await axios
          .post("/api/comments", commentToSave)
          .then((res) => res.data);

        const comment = {
          img: "https://image.flaticon.com/icons/png/512/64/64572.png",
          ...savedComment,
        };

        this.comments.push(comment);
        this.value = "";
        this.name = "";
      },
      increaseTextDescription() {
        if (this.visible) {
          setTimeout(() => {
            const elements = document.getElementsByClassName(
              "toobar-btn toolbar-btn__resize"
            );
            while (elements.length > 0) {
              elements[0].parentNode.removeChild(elements[0]);
            }
          }, 100);
          setTimeout(() => {
            const images = document.getElementsByClassName("vel-img");
            const image = images[0];
            if (image) {
              image.height = "350";
            }
          }, 200);
        }
      },
      uploadedPhoto(photo) {
        this.imgs.push(photo);
      },
      goToNearbyArtwork({id, friendly_id}) {
        this.$router.push(`/art/${friendly_id || id}`, () => {
          this.$router.go(0);
        });
      },
    },
  };
</script>

<style>
  .vel-img-title {
    font-size: 20px !important;
    color: white !important;
  }
</style>

<style scoped>
  .ml {
    margin-left: 10px;
  }

  .mt30 {
    margin-top: 30px;
  }

  .about-page {
    width: 90%;
    padding: 0 5%;
  }

  .img-container {
    display: flex;
    width: 100%;
    height: 650px;
    position: relative;
  }

  .img-galery {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
  }

  .img-next {
    width: 50%;
    height: 50%;
  }

  .img-btn,
  .img-btn:hover {
    outline: none;
    font-size: 1.2rem;
    font-weight: 300;
    width: 150px;
    color: #000;
    border-radius: 5px;
    height: 50px;
    cursor: pointer;
    /* position: absolute; */
    /* bottom: 30px; */
    /* right: 100px; */
    background: #ffffff;
  }

  .content {
    display: flex;
    margin-top: 80px;
    padding: 0 5%;
  }

  .info {
    width: 70%;
    margin-bottom: 30px;
  }

  .info-title {
    font-size: 3.3rem;
    font-weight: bold;
  }

  .info-by {
    margin-top: 10px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999;
  }

  .who {
    display: flex;
    font-size: 2rem;
    font-weight: 400;
  }

  .name {
    min-width: 400px;
  }

  .links {
    width: 300px;
  }

  .visit {
    text-decoration: none;
    cursor: pointer;
    color: #333;
    font-size: 1.2rem;
  }

  .more-info {
    font-size: 1.3rem;
  }

  .info-item {
    margin-top: 10px;
    color: #4860a0;
    font-size: 300;
  }

  .info-item strong {
    color: #000;
    font-size: bold;
  }

  .about {
    margin: 30px 0;
    white-space: pre-line;
  }

  .comments {
    margin-top: 50px;
    width: 100%;
  }

  .total {
    font-size: 2rem;
    font-weight: bold;
  }

  .comments-input {
    margin-top: 10px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .avatar {
    margin-right: 20px;
    max-height: 40px;
    min-height: 40px;
    min-width: 40px;
    max-width: 40px;
    border-radius: 50%;
  }

  .comments-input input {
    outline: none;
    padding-left: 20px;
    font-size: 1.2rem;
    height: 50px;
    width: 98%;
    border: 1px solid #eee;
    border-radius: 5px;
  }

  .comment {
    display: flex;
    margin-bottom: 50px;
  }

  .comment-item,
  .on {
    font-size: 1.2rem;
    font-weight: 300;
  }

  .on {
    margin-left: 10px;
  }

  .comment-name {
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  .map {
    width: 30%;
    margin-left: 30px;
  }

  .map-component {
    height: 400px;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    background: #eee;
  }

  .location {
    font-size: 1.2rem;
    margin-top: 50px;
    font-weight: 300;
    width: 100%;
  }

  .loc {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .loc-text {
    width: 50%;
  }

  .loc-link {
    color: #4860a0;
    text-decoration: none;
    cursor: pointer;
  }

  .more-arts {
    width: 100%;
  }

  .more-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .more-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .more-list-item {
    width: 170px;
    margin-bottom: 20px;
    margin-right: 10px;
  }

  .more-list-img {
    cursor: pointer;
    width: 170px;
    height: 100px;
  }

  .more-list-item-title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 10px;
  }

  .more-list-item-subtitle {
    display: flex;
    color: #999;
    font-weight: 300;
  }

  .name-item {
    margin-left: 5px;
    color: #4860a0;
    cursor: pointer;
  }

  .mt {
    margin-top: 300px;
  }
</style>
